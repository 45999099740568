import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
    realm: 'corp',
    url: 'https://qa-access-key-corp.falabella.tech/auth/',
    sslRequired: 'external',
    clientId: 'DUW',
    confidentialPort: 0,
    onLoad: 'login-required'
});

export default keycloak;