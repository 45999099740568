import React from 'react';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload} from "@fortawesome/free-solid-svg-icons";


function DownloadTableButton({ onClick }) {
  const handleDownloadClick = () => {
    const headers = new Headers();
    headers.append('bu', 'SO');

    fetch('http://localhost:4001/user-request', {
      method: 'GET',
      headers: headers,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error('Error al realizar la solicitud al servicio.');
          return null;
        }
      })
      .then((data) => {
        if (data && data.data) {
            const dataWithConcatenatedRut = data.data.map((item) => ({
                ...item,
                rut: item.rut.join(', ')
              }));
            console.log(dataWithConcatenatedRut)
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.json_to_sheet(dataWithConcatenatedRut);
          XLSX.utils.book_append_sheet(wb, ws, 'Tabla');


          XLSX.writeFile(wb, 'tabla.xlsx');
        } else {
          console.error('No se encontraron datos en la respuesta del servicio.');
        }
      })
      .catch((error) => {
        console.error('Error al realizar la solicitud:', error);
      });
  };

  return (
    <button className="download-button" onClick={handleDownloadClick}>
        <FontAwesomeIcon icon={faDownload} className="icon" />
        Descargar tabla
    </button>
  );
}

export default DownloadTableButton;
