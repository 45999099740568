import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import keycloak from './services/keycloak';
import LoadingScreen from './LoadingScreen';

function Root() {
  const [keycloakInitialized, setKeycloakInitialized] = useState(false);

  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak
      .init({ onLoad: 'login-required' })
      .then(() => {
        setKeycloakInitialized(true);
      });
    }
  }, []);

  return (
    <React.StrictMode>
      {keycloakInitialized ? (
          <App />
      ) : (
        <LoadingScreen />
      )}
    </React.StrictMode>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
