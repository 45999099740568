// FileUploader.js
import React from 'react';
import * as XLSX from 'xlsx';
import "../App.css";

function FileUploader() {

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        // const jsonResult = XLSX.utils.sheet_to_json(sheet);
        
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const rutArray = jsonData
          .map((row) => row[0]) 
          .filter((rut) => rut); 

        if (rutArray.length > 0) {
         
          const requestData = {
              usuario: "test",
              rol: "test",
              bu: "SO",
              rut: rutArray
          };
        
          console.log(requestData)

        
        fetch('http://localhost:4001/user-request', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'bu': "SO"
            },
            body: JSON.stringify(requestData),
          })
            .then((response) => {
              if (response.ok) {
                console.log('Solicitud exitosa. El servicio respondió correctamente.');
              } else {
                console.error('Error al realizar la solicitud al servicio.');
              }
            })
            .catch((error) => {
              console.error('Error al realizar la solicitud:', error);
            });
        }else{
            console.log('No se encontraron datos en el archivo.');
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div className="attach-file">
        <p className="solicitud">Nueva solicitud</p>
      <label htmlFor="fileInput" className="file-input-label">
        Adjuntar archivo .xlsx o .csv
      </label>
      <input
        type="file"
        id="fileInput"
        accept=".xlsx, .csv"
        className="file-input"
        onChange={handleFileChange}
      />
       <button className="file-action-button">Cncel</button>
        <button className="file-action-button">OK</button>
    </div>
    
  

  );
}

export default FileUploader;
