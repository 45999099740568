import React, { useState } from 'react';
import logoImage from '../images/logo2.png'; 
import keycloak from '../services/keycloak';

function Navbar() {

  const [userName, setUserName] = useState("");

  if(keycloak.authenticated) {
    keycloak.loadUserInfo().then((userInfo) => {
      console.log("userInfo: ", userInfo);
      setUserName(userInfo.name);
    });
  }

  const handleLogout = () => {
    keycloak.logout();
    // window.location.reload();
  };

  return (
    <nav>
      <div className="navbar">
        <div className="navbar-logo">
          <img src={logoImage} alt="Logo" />
        </div>
        <div className="navbar-user">
          Bienvenido { userName }
          <button className="logout-button" onClick={handleLogout}>Cerrar sesión</button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
