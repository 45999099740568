import React from "react";
import Navbar from "./components/Navbar";
import "./App.css";
import FileUploader from './components/fileUploader';
import DownloadTableButton from './components/downloadFile'; // Importa el componente de descarga de tabla

function HomePage() {
  return (
    <div>
      <Navbar />
      <h2 className="title">Desactivación de usuario WMOS (Bodega)</h2>
      <div className="content">
        <div className="options">
        <DownloadTableButton />
          <div className="button-separator"></div>
          <FileUploader/>
          
        </div>
      </div>
      {/* <div className="table-container">
        <table className="reference-table">
          <thead>
            <tr>
              <th>Columna 1</th>
              <th>Columna 2</th>
              <th>Columna 3</th>
              <th>Columna 4</th>
              <th>Columna 5</th>
              <th>Columna 6</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 8 }).map((_, rowIndex) => (
              <tr key={rowIndex}>
                <td>Fila {rowIndex + 1}, Col 1</td>
                <td>Fila {rowIndex + 1}, Col 2</td>
                <td>Fila {rowIndex + 1}, Col 3</td>
                <td>Fila {rowIndex + 1}, Col 4</td>
                <td>Fila {rowIndex + 1}, Col 5</td>
                <td>Fila {rowIndex + 1}, Col 6</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}
    </div>
  );
}

export default HomePage;
